<template lang="pug">
div
  .curp(@click='customChange("children", -1)'): SvgComponent(
    name='minus',
    :style='{ color: children ? "#D41E34" : "B9BEC3" }'
  )
  .filters-actionCounter {{ children }}
  .curp(@click='customChange("children", 1)'): SvgComponent(name='plus')
</template>

<script>
  export default {
    methods: {
      customChange() {
        this.$emit('customChange');
      },
    },
    props: ['value'],
  };
</script>

<style lang="scss"></style>
