<template lang="pug">
section.catalog
  .catalog-titlebox: h2 Caribbean real estate
  //- .catalog-tipbox: .catalog-tip Choose one of the regions to <br>see all real estate
  .catalog-tipbox: .catalog-tip Choose your options to see <br>see all relevant real estate

  //- .catalog-actions.actions
  //-   .actions-arrow._left(
  //-     @click='longScroll($event, "prev", actionStart)',
  //-     :style='actionStart ? { color: "#C4C4C4" } : {}'
  //-   ): SvgComponent(
  //-     name='arrow'
  //-   )
  //-   .actions-buttons
  //-     .actions-button(
  //-       v-for='location in locations',
  //-       :class='{ _active: activeLocation == location.id }',
  //-       @click='activeLocation == location.id ? (activeLocation = null) : (activeLocation = location.id)'
  //-     ) {{ location.Title }}
  //-   .actions-arrow._right(
  //-     @click='longScroll($event, "next", actionEnd)',
  //-     :style='actionEnd ? { color: "#C4C4C4" } : {}'
  //-   ): SvgComponent(
  //-     name='arrow'
  //-   )
  .catalog-filters.filters(
    v-if='allFilters',
    v-on:keyup.enter='reloadListings',
    :style='{ marginBottom: extraIsVisible ? ($store.getters.desktop2 ? "250px" : activePurpose == "for_rent" ? "400px" : "300px") : $store.getters.desktop2 ? "100px" : "60px" }'
  )
    //- Фильтры отдельно запилить в компонент
    .filters-tabs
      .filters-tab(
        :class='{ _active: activePurpose == "for_sale" }',
        @click='$router.push({ name: "ListingsPage", params: { for: "for_sale" } })'
      ) For sale
      //- .filters-tab(
      //-   :class='{ _active: activePurpose == "for_rent" }',
      //-   @click='$router.push({ name: "ListingsPage", params: { for: "for_rent" } })'
      //- ) For rent
    .filters-extra.extra
      .extra-toggle(v-if='!extraIsVisible', @click='extraIsVisible = true')
        span all filters
        .bounce: SvgComponent(name='arrow')
      .extra-toggle(v-else, @click='extraIsVisible = false')
        span close
        SvgComponent(name='close', style='transform: scale(0.7); transform-origin: left')
      .extra-row(v-if='extraIsVisible && activePurpose == "for_sale"')
        #price-range.extra-section
          .extra-label Living space
          template
            ASlider(
              :min='minmaxes.sqftFrom',
              :max='minmaxes.sqftTo',
              :tooltip-visible='true',
              range,
              v-model='sqftRange',
              :step='10',
              :tipFormatter='(e) => currency(e) + " SQFT"',
              tooltipPlacement='bottom'
            )
        .extra-section
          .extra-label Street
          input.extra-input(placeholder='Address', v-model='address')
      .extra-row(v-if='extraIsVisible && activePurpose == "for_rent"')
        .extra-section
          .extra-label Bathrooms
          .extra-filter(v-if='$store.getters.desktop2')
            .curp(@click='customChange("baths", -1)'): SvgComponent(
              name='minus',
              :style='{ color: baths > 0 ? "#000" : "#B9BEC3" }'
            )
            .filters-actionCounter {{ baths }} baths
            .curp(@click='customChange("baths", 1)'): SvgComponent(name='plus')
          .filters-input._tabfix(v-else)
            .filter-phoneCounterAction(@click='customChange("baths", -1)'): SvgComponent(
              name='minus2'
            )
            .filter-phoneCounterNumber {{ baths }}
            .filter-phoneCounterAction(@click='customChange("baths", 1)'): SvgComponent(name='plus2')
        .extra-section
          .extra-label Living space
          template
            ASlider(
              :min='minmaxes.sqftFrom',
              :max='minmaxes.sqftTo',
              :tooltip-visible='true',
              range,
              v-model='sqftRange',
              :step='10',
              :tipFormatter='(e) => currency(e) + " SQFT"',
              tooltipPlacement='bottom'
            )
        .extra-section
          .extra-label Price
          template
            ASlider(
              :min='minmaxes.priceFrom',
              :max='minmaxes.priceTo',
              :tooltip-visible='true',
              range,
              v-model='priceRange',
              :step='1000',
              :tipFormatter='(e) => "$" + currency(e)',
              tooltipPlacement='bottom'
            )
    .filters-wrapper
      .filters-section
        .filters-inputGroup(v-if='activePurpose == "for_sale"')
          label.filters-label From
          money.filters-input(v-model='priceFrom', v-bind='money')
        .filters-inputGroup(v-else)
          label.filters-label Start
          .filters-input._date(@click='showPopup = "datapicker"') {{ formatDate(dateFrom) }}
        span.filters-minus —
        .filters-inputGroup._fix(v-if='activePurpose == "for_sale"')
          label.filters-label To
          money.filters-input(v-model='priceTo', v-bind='money')
        .filters-inputGroup(v-else)
          label.filters-label End
          .filters-input._date(@click='showPopup = "datapicker"') {{ formatDate(dateTo) }}
        .curp.dNoneOnlyTablet(
          @click='showPopup = showPopup == "datapicker" ? null : "datapicker"',
          v-if='activePurpose == "for_rent"',
          style='margin-left: 10px'
        ): SvgComponent(
          name='calendar'
        )
        SvgComponent(name='tag', v-else, style='margin-left: 10px')
      .filters-divider
      .filters-section
        .filters-inputGroup._action(
          v-if='activePurpose == "for_sale"',
          @click='showPopup = "baths"'
        )
          label.filters-label Bathroom
          .filters-input
            .filter-phoneCounterAction(@click='customChange("baths", -1)'): SvgComponent(
              name='minus2'
            )
            .filter-phoneCounterNumber {{ baths }}
            .filter-phoneCounterAction(@click='customChange("baths", 1)'): SvgComponent(name='plus2')
        .filters-action(v-if='showPopup == "baths"'): .filters-actionContent
          span.filters-actionTitle Baths
          .curp(@click='customChange("baths", -1)'): SvgComponent(
            name='minus',
            :style='{ color: baths > 0 ? "#D41E34" : "#B9BEC3" }'
          )
          .filters-actionCounter {{ baths }}
          .curp(@click='customChange("baths", 1)'): SvgComponent(name='plus')
        .filters-inputGroup._action(
          v-if='activePurpose == "for_rent"',
          @click='showPopup = "guests"'
        )
          label.filters-label Guests
          .filters-input
            .filter-phoneCounterAction(@click='customChange("adults", -1, 0)'): SvgComponent(
              name='minus2'
            )
            .filter-phoneCounterNumber {{ guests }}
            .filter-phoneCounterAction(@click='customChange("adults", 1, 0)'): SvgComponent(
              name='plus2'
            )
        .filters-action(v-if='showPopup == "guests"')
          .filters-actionContent
            span.filters-actionTitle Adults
            .curp(@click='customChange("adults", -1, 0)'): SvgComponent(
              name='minus',
              :style='{ color: adults > 1 ? "#D41E34" : "#B9BEC3" }'
            )
            .filters-actionCounter {{ adults }}
            .curp(@click='customChange("adults", 1, 0)'): SvgComponent(name='plus')
          .filters-actionContent
            span.filters-actionTitle Children
            .curp(@click='customChange("children", -1)'): SvgComponent(
              name='minus',
              :style='{ color: children > 0 ? "#D41E34" : "#B9BEC3" }'
            )
            .filters-actionCounter {{ children }}
            .curp(@click='customChange("children", 1)'): SvgComponent(name='plus')
        .filters-bg(v-if='showPopup', @click='showPopup = null') bg
        .tabletNone.curp(
          @click='showPopup = showPopup ? null : activePurpose == "for_rent" ? "guests" : "baths"'
        ): SvgComponent(
          name='filter-arrow'
        )
      .filters-divider.phoneNone
      .filters-section
        .filters-inputGroup._action(@click='showPopup = "beds"')
          label.filters-label Bedrooms
          .filters-input
            .filter-phoneCounterAction(@click='customChange("beds", -1)'): SvgComponent(
              name='minus2'
            )
            .filter-phoneCounterNumber {{ beds }}
            .filter-phoneCounterAction(@click='customChange("beds", 1)'): SvgComponent(name='plus2')
        .filters-action(v-if='showPopup == "beds"'): .filters-actionContent
          span.filters-actionTitle Rooms
          .curp(@click='customChange("beds", -1)'): SvgComponent(
            name='minus',
            :style='{ color: beds > 0 ? "#D41E34" : "#B9BEC3" }'
          )
          .filters-actionCounter {{ beds }}
          .curp(@click='customChange("beds", 1)'): SvgComponent(name='plus')
        .tabletNone.curp(@click='showPopup = showPopup ? null : "beds"'): SvgComponent(
          name='filter-arrow'
        )
    .button.filters-button(@click='reloadListings', tabindex='0') SEARCH
    v-range-selector.datapicker._catalog(
      :start-date.sync='dateFrom',
      :end-date.sync='dateTo',
      :class='{ _active: showPopup == "datapicker" }'
    )
    .datapicker-header
      .datapicker-logo: SvgComponent(name='logo')
      .datapicker-close(@click='showPopup = null'): SvgComponent(name='close')
  .catalog-listings.listings(:class='{ _shortList: shortList }')
    template(v-if='!loading')
      ListingItem(v-for='listing in listings', :listing='listing', :key='listing.id')
      .button._blackReverse._more(@click='shortList = false', v-if='shortList && !activeLocation') see other {{ listings.length - 3 }} listings
    span.catalog-status(v-else) loading...
    span.catalog-status(v-if='!loading && listings && !listings[0]') no items
</template>

<script>
  import ListingItem from '@/components/ListingItem.vue';
  // import VRangeSelector from 'vuelendar/components/vl-range-selector';
  import VRangeSelector from '@/components/vuelendar/components/vl-range-selector';
  import Counter from '@/components/Counter.vue';
  import { Money } from 'v-money';

  // ANT
  import { Slider } from 'ant-design-vue';
  import 'ant-design-vue/lib/slider/style/index.css';
  import 'ant-design-vue/lib/tooltip/style/index.css';

  export default {
    data() {
      return {
        address: '',
        extraIsVisible: false,
        shortList: true,
        showPopup: null,
        loading: false,
        activeLocation: null,
        listings: [],
        locations: [],
        priceFrom: 1,
        priceTo: 99000000,
        priceRange: [1, 10000],
        sqftRange: [1, 10000],
        dateFrom: null,
        dateTo: null,
        baths: 0,
        beds: 0,
        adults: 1,
        children: 0,
        buttonActionWidth: 0,
        money: {
          decimal: 'C',
          thousands: ',',
          prefix: '$ ',
          suffix: '',
          precision: 0,
          masked: false,
        },
        actionHover: false,
        actionFlag: false,
        timerId: null,
        actionStart: true,
        actionEnd: false,
        minmaxes: {
          priceFrom: 1,
          priceTo: 10000,
          sqftFrom: 1,
          sqftTo: 50000,
        },
      };
    },
    props: {
      allFilters: Boolean,
    },
    async mounted() {
      if (this.allFilters) {
        this.shortList = false;
      }

      const d = new Date();
      const d2 = new Date(d.getTime() + 86400000);
      this.dateFrom = d.toISOString();
      this.dateTo = d2.toISOString();
      this.reloadListings();
      this.locations = await this.$store.dispatch('getLocations');
      const minmax = await this.$store.dispatch('minmax');
      this.priceFrom = minmax.min;
      this.priceTo = minmax.max;
      this.priceRange[0] = minmax.min;
      this.priceRange[1] = minmax.max;
      this.minmaxes.priceFrom = minmax.min;
      this.minmaxes.priceTo = minmax.max;
      // refact
    },

    components: {
      ASlider: Slider,
      VRangeSelector,
      ListingItem,
      Counter,
      Money,
      SvgComponent: () => import('@/components/SvgComponent'),
    },
    watch: {
      activeLocation() {
        this.reloadListings();
      },
      activePurpose() {
        this.reloadListings();
      },
      dateTo() {
        if (this.dateTo) {
          this.showPopup = null;
        }
      },
    },
    computed: {
      guests: function () {
        return this.children + this.adults;
      },
      activePurpose: function () {
        if (!this.allFilters) {
          return false;
        } else {
          return this.$route.params.for ? this.$route.params.for : 'for_sale';
        }
      },
    },
    methods: {
      currency: function (value) {
        if (typeof value !== 'number') {
          return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 0,
        });
        return formatter.format(value);
      },
      alert: function () {
        alert(1);
      },

      customChange(state, param, minmin = -1) {
        if (this[state] + param > minmin) {
          this[state] += param;
        }
      },
      reloadListings: async function () {
        this.showPopup = null;
        this.loading = true;
        const params = {
          location: this.activeLocation,
          baths: this.activePurpose == 'for_sale' ? this.baths : null,
          guests: this.activePurpose == 'for_rent' ? this.guests : null,
          beds: this.beds,
          purpose: this.activePurpose,
          priceFrom: this.activePurpose == 'for_sale' ? this.priceFrom : null,
          priceTo: this.activePurpose == 'for_sale' ? this.priceTo : null,
          dateFrom: this.activePurpose == 'for_rent' ? this.dateFrom : null,
          dateTo: this.activePurpose == 'for_rent' ? this.dateTo : null,
        };
        if (this.extraIsVisible) {
          params.sqftFrom = this.sqftRange[0];
          params.sqftTo = this.sqftRange[1];
        }
        if (this.extraIsVisible && this.activePurpose == 'for_rent') {
          params.baths = this.baths;
          params.priceFrom = this.priceRange[0];
          params.priceTo = this.priceRange[1];
        }
        if (this.extraIsVisible && this.activePurpose == 'for_sale') {
          params.address = this.address;
        }
        this.listings = await this.$store.dispatch('getListings', params);
        this.loading = false;
      },

      longScroll: function (e, arg, c) {
        if (!this.$store.getters.desktop) {
          this.activeLocation = null;
        }
        if (this.$store.getters.desktop || (!c && !this.actionFlag)) {
          this.actionFlag = true;
          setTimeout(() => {
            const buttonWidth = document.querySelector('.actions-button').offsetWidth;
            const actionsButtons = document.querySelector('.actions-buttons');
            const pll =
              this.actionEnd && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
                ? -0
                : 0;
            actionsButtons.scroll(
              actionsButtons.scrollLeft + (arg == 'next' ? buttonWidth : -buttonWidth) + pll,
              0,
            );
            this.checkEdgeActions();
          }, 50);
          setTimeout(() => {
            this.actionFlag = false;
          }, 300);
        }
      },

      shortScroll: function (value, interval) {
        if (this.$store.getters.desktop) {
          if (this.actionHover == false) {
            this.actionHover = true;
            const actionsButtons = document.querySelector('.actions-buttons');
            this.timerId = setInterval(() => {
              this.checkEdgeActions();
              actionsButtons.scroll(actionsButtons.scrollLeft + value, 0);
              if (this.actionHover == false) {
                clearInterval(this.timerId);
              }
            }, interval);
          }
        }
      },

      checkEdgeActions() {
        const actionsButtons = document.querySelector('.actions-buttons');
        let scrollRight =
          actionsButtons.scrollWidth - actionsButtons.clientWidth - actionsButtons.scrollLeft;
        this.actionStart = !actionsButtons.scrollLeft;
        this.actionEnd = !scrollRight;
        console.log(actionsButtons.scrollLeft, scrollRight);
      },

      actionHoverFalse() {
        this.actionHover = false;
      },
      formatDate(date) {
        let dateObj = new Date(date);
        return dateObj.toLocaleString('en', { weekday: 'short', month: 'short', day: 'numeric' });
      },
    },
  };
</script>
<style src="vuelendar/scss/vuelendar.scss" lang="scss"></style>
<style lang="scss">
  .catalog-status {
    @include tablet {
      padding-left: 15px;
    }
  }
  .ant-slider {
    margin-top: 35px;
    width: 280px;
    @include small {
      width: 200px;
    }
    @include tablet {
      width: 317px;
    }
    @include phone {
      width: calc(100% - 10px);
    }
  }

  .ant-slider-rail {
    background: rgb(196, 196, 196) !important;
    height: 2px;
    &:after,
    &:before {
      content: '';
      height: 12px;
      width: 12px;
      border-radius: 100%;
      transform: translateY(-50%);
      position: absolute;
      border: 2px solid #c4c4c4;
      background: white;
      z-index: 0;
      top: 1px;
    }
    &:before {
      left: -6px;
    }
    &:after {
      right: -6px;
    }
  }
  .ant-slider-track {
    height: 2px;
    background: #000 !important;
  }
  .ant-slider-step {
    // background: #000 !important;
  }
  .ant-slider-handle {
    border-color: black !important;
    // top: 3px;
    height: 12px;
    width: 12px;
    box-shadow: none !important;
    &:active {
      border-color: $red !important;
      background-color: $red !important;
    }
    &:hover {
      border-color: $red !important;
    }
  }
  .ant-tooltip {
    z-index: 1;
  }

  .extra {
    width: 100%;
  }

  .simple-range-slider {
    @include tablet {
      width: 100% !important;
    }
  }

  .extra-toggle {
    display: flex;
    align-items: center;
    color: $red;
    font-size: 24px;
    @include middle {
      font-size: 16px;
    }
    @include phone {
      font-size: 24px;
    }
    font-weight: 600;
    height: 1em;
    cursor: pointer;
    svg {
      transform: scale(-1);
      margin-left: 20px;
    }
  }

  .extra-row {
    display: flex;
    margin-top: 50px;
    @include tablet {
      margin-top: 30px;
      flex-direction: column;
      @include gap(30px, col);
    }
  }

  .extra-label {
    color: #c4c4c4;
    font-size: 16px;
    & + .extra-filter {
      margin-top: 30px;
    }
    @include tablet {
      margin-bottom: 16px;
    }
  }

  .extra-filter {
    display: flex;
    .filters-actionCounter {
      margin: 0 auto;
    }
  }
  .extra-input {
    margin-top: 10px;
    border: 2px solid black;
    padding: 13px 21px;
    outline: none !important;
    &::placeholder {
      color: #c4c4c4;
      font-size: 16px;
    }
    @include tablet {
      width: 100%;
    }
  }
  .extra-section {
    .filters-actionCounter {
      width: 138px;
      font-weight: bold;
    }
    padding: 0 48px;
    @include nophone {
      &:not(:first-child) {
        border-left: #ededed 1px solid;
      }
      &:last-child {
        padding-right: 0;
      }
      &:first-child {
        padding-left: 0;
      }
    }
    @include tablet {
      padding: 0;
    }
  }
  .tooltip {
    background: transparent !important;
    color: black !important;
    span {
      position: relative;
      bottom: -60px;
    }
  }
  .popper__arrow {
    display: none !important;
  }
  .range {
    width: 280px;
    margin-top: 20px;
    padding: 0 10px;
    @include small {
      width: 200px;
    }
    @include tablet {
      width: 317px;
    }
    @include phone {
      width: 100%;
    }

    .el-slider__runway {
      height: 2px;
      background-color: #c4c4c4;
      &:after,
      &:before {
        content: '';
        height: 12px;
        width: 12px;
        border-radius: 100%;
        transform: translateY(-50%);
        position: absolute;
        border: 2px solid #c4c4c4;
        background: white;
        z-index: 0;
        top: 1px;
      }
      &:before {
        left: -6px;
      }
      &:after {
        right: -6px;
      }
    }
    .el-slider__bar {
      height: 2px;
      background-color: black;
    }
    .el-slider__button-wrapper {
      top: -17px;
      z-index: 2;
    }
    .el-slider__button {
      height: 12px;
      width: 12px;
      border-color: black;
      &.dragging {
        background: $red;
        border-color: $red;
      }
    }
  }

  .simple-range-slider-bg-bar {
    height: 2px !important;
    &:after,
    &:before {
      content: '';
      height: 12px;
      width: 12px;
      border-radius: 100%;
      top: 0;
      transform: translateY(-50%);
      position: absolute;
      border: 2px solid #c4c4c4;
      background: white;
      z-index: 0;
    }
    &:before {
      content: '';
      left: -8px;
    }
    &:after {
      content: '';
      right: -5px;
    }
  }
  .simple-range-slider-anchor {
    width: 12px !important;
    height: 12px !important;
    border: 2px solid black;
    box-shadow: none !important;
    z-index: 1;
    &:active {
      border: 2px solid $red !important;
    }
  }
  ._popover-dollars .simple-range-slider-popover:before {
    content: '$';
  }
  ._popover-sqft .simple-range-slider-popover:after {
    content: 'SQFT';
  }
  .simple-range-slider-popover {
    bottom: -2em !important;
    box-shadow: none !important;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .simple-range-slider-popover-arrow {
    visibility: hidden !important;
  }
  .simple-range-slider-handle:active {
    box-shadow: none !important;
    background: $red !important;
    opacity: 1 !important;
  }
  .simple-range-slider-handle:focus {
    box-shadow: none !important;
  }

  .filters {
    &-extra {
      position: absolute;
      top: 100%;
      margin-top: 50px;
    }
    &-bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    margin-top: 160px;
    display: flex;
    position: relative;
    user-select: none;
    @include phone {
      flex-direction: column;
      margin-top: 26px;
    }
    &-wrapper {
      display: flex;
      font-size: 24px;
      @include middle {
        font-size: 16px;
      }
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      align-items: center;
      padding: 23px 30px;
      width: 100%;
      z-index: 1;
      background: white;
      @include phone {
        font-size: 24px;

        flex-direction: column;
        box-shadow: none;
        padding: 24px 0;
        width: auto;
      }
    }
    &-section {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: flex-end;
      svg {
        // margin-bottom: 3px;
      }
      @include phone {
        width: 100%;
        &:last-child {
          margin-top: 24px;
        }
      }
    }
    &-inputGroup {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &._vertical {
        border-bottom: #ededed 2px solid;
        padding-bottom: 10px;
        .filters-input {
          width: 100%;
        }
        &:not(:first-child) {
          margin-bottom: 30px;
        }
      }
      &._action {
        @include tablet {
          width: 100%;
        }
      }
      &._fix {
        @include tablet {
          padding-left: 10px;
        }
      }
    }
    &-label {
      color: #c4c4c4;
      font-size: 16px;
      margin-bottom: 15px;
      @include tablet {
        // margin-bottom: 35px;
      }
    }

    &-divider {
      margin: 0 70px;
      width: 2px;
      height: 100%;
      background: #ededed;
      @include middle {
        margin: 0 35px;
      }
      @include tablet {
        margin: 0 15px;
      }
      @include phone {
        height: 2px;
        width: 100%;
        margin: 16px 0 24px;
      }
    }
    &-minus {
      margin: 0 70px;
      @include middle {
        margin: 0 30px;
      }
      @include tablet {
        margin: 0 auto;
        // flex-grow: 1;
      }
    }
    &-tabs {
      position: absolute;
      text-transform: uppercase;
      font-weight: 600;
      display: flex;

      transform: translateY(-100%);
      z-index: 0;
      // gap: 10px;
      @include phone {
        z-index: 2;
        position: static;
        transform: none;
        text-align: center;
      }
    }
    &-tab {
      position: relative;
      z-index: 1;
      cursor: pointer;
      padding: 30px;
      background: #f1f1f1;
      transition: 0.1s;
      &._active {
        color: $red;
        background: white;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
      }
      @include middle {
        font-size: 16px;
      }
      @include tablet {
        flex-grow: 1;
        font-size: 18px;
      }
    }
    // &-tab, &-input{
    //   border: gray 1px solid;
    //   width: 100px;
    // }
    &-button {
      height: auto;
      background: $red;
      color: white;
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: lowercase;
      font-weight: 600;
      cursor: pointer;
      font-size: 24px;
      position: relative;
      z-index: 1;
      @include middle {
        // min-width: 110px;
        font-size: 16px;
      }
      @include phone {
        width: 100%;
        height: 64px;
        font-size: 24px;
      }
      &:focus {
        filter: brightness(0.5);
      }
    }
    &-action {
      position: absolute;
      padding: 30px;
      width: 236px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      background: white;
      top: calc(100% + 10px);
      font-size: 20px;
      line-height: 24px;
      @include gap(15px, col);
      @include middle {
        font-size: 16px;
      }
      @include phone {
        display: none;
        font-size: 20px;
      }

      &Title {
        font-weight: 600;
        margin-right: auto;
      }
      &Content {
        display: flex;
        align-items: center;
      }
      &Counter {
        width: 40px;
        text-align: center;
        // transform: translateX(-50%);
        // position: relative;
        // left: 50%;
      }
      .svg-minus,
      .svg-plus {
        color: $red;
        cursor: pointer;
      }
    }
  }
  .catalog {
    display: contents;
    &-titlebox {
      padding-top: 150px;
      padding-bottom: 50px;
      @include tablet {
        text-align: center;
        h2 {
          font-size: 36px;
          line-height: 43px;
        }
      }
    }
    &-tipbox {
      padding-top: 150px;
      padding-bottom: 50px;
      text-align: right;
    }
    &-tip {
      color: $red;
      font-weight: 600;
    }
  }
  .actions {
    height: 128px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    @include tablet {
      height: 70px;
    }
    position: relative;
    &-buttons {
      scrollbar-width: none;
      height: 100%;
      z-index: 1;
      flex-grow: 1;
      scroll-behavior: smooth;
      display: grid;
      // max-width: calc(100% - 240px);
      // pereat = locations.length
      grid-template-columns: repeat(6, 20%);
      overflow-x: hidden;
      @include tablet {
        grid-template-columns: repeat(6, calc(33.33%));
      }
      @include phone {
        grid-template-columns: repeat(6, calc(50%));
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
    &-button {
      font-size: 20px;
      user-select: none;
      font-weight: 600;
      cursor: pointer;
      text-align: center;
      line-height: 128px - 8px;
      border: 2px solid transparent;
      transition-duration: 0.2s;
      transition-property: color, border-color, background-color;
      &:hover {
        color: $red;
        border-color: $red;
      }
      @include middle {
        font-size: 18px;
      }
      @include tablet {
        line-height: 70px;
        height: 70px;
      }
      &._active {
        background: $red;
        color: white;
      }
    }
    &-arrow {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      // padding: 20px;
      position: relative;
      // top: 50%;
      // transform: translateY(-50%);
      cursor: pointer;
      color: black;
      transition: 0.3s color;
      @include nophone {
        &:hover {
          color: $red;
        }
      }
      &._left {
        left: 0;
        @supports (-webkit-touch-callout: none) {
          &:after {
            @include tablet {
              content: '';
              background: white;
              z-index: 1111;
              width: 2px;
              height: calc(100% - 4px);
              top: 2px;
              position: absolute;
              right: 0px;
            }
          }
        }

        // border-right: 3px solid white;
      }
      &._right {
        border-left: 3px solid white;
        right: 0;
        transform: scaleX(-1);
      }
    }
  }
  .listings {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    margin-top: 50px;
    margin-bottom: 150px;
    gap: 50px 50px;
    @include middle {
      gap: 20px;
    }

    @include tablet {
      grid-template-columns: 1fr;
      gap: 38px;
      margin-bottom: 60px;
    }
    @include onlytablet {
      grid-template-columns: 1fr 1fr;
      margin: 30px 50px 60px;
      gap: 28px;
    }
    &._shortList {
      & > .listing:nth-child(n + 4) {
        display: none;
      }
      .button._more {
        grid-column: 2;
        @include tablet {
          grid-column: span 2;
          // margin: 0 50px;
        }
        @include phone {
          margin: 0 16px;
          grid-column: auto;
        }
      }
    }
    &-moreButton {
      grid-column: 2;
      border: 2px black solid;
      height: 70px;
      text-align: center;
      line-height: 68px;
      cursor: pointer;
      font-weight: 600;
    }
  }
  .filter-phoneCounterAction {
    display: none;
  }

  ._popover-dollars,
  ._popover-sqft {
    width: 300px;
    @include small {
      width: 200px;
    }
  }
</style>
